import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchResultCardComponent } from './search-result-card';

import {
  BbDrugIcon,
  BbRoutineIcon,
} from '../../../blackbook-components/quarks/icons';
import { Spinner } from '../../../blackbook-components/quarks/spinner';
import { useTrack } from '../../../services/hooks/useTrack';
import { SearchContainerStyle } from './style';
import cidCats from '../../../context/utils/CIDJson/categoria.json';
import cidGroups from '../../../context/utils/CIDJson/grupos.json';
import cidCaps from '../../../context/utils/CIDJson/capitulos.json';
import { Calculator } from '../../../assets/icons/Calculator';
import { FilterContainer } from '../style';
import { EmptySearchImage } from '../../../assets/icons/EmptySearchImage';
import { CidIconSimple } from '../../../assets/icons/CidIconSimple';

export function SearchComponent({ isOpen, onClose, response, inputValue }) {
  const history = useHistory();

  const { trackEvent } = useTrack();

  function getAbsolutePosition(type, itemId) {
    const elements = [].slice.call(
      document.querySelectorAll(`.--track-search-mark-${type}`)
    );
    const itemIndex = elements.findIndex((e) => e.dataset.itemId === itemId);
    return itemIndex + 1;
  }

  function getRelativePosition(parent, item, isParent, type) {
    if (isParent) {
      const itemIndex = response.searchResult[`${type}s`].data.findIndex(
        (e) => e.item_id === item.item_id && e.legend_id === item.legend_id
      );
      return itemIndex + 1;
    }
    const itemIndex = parent.sub_items.findIndex(
      (e) => e.sub_item_id === item.sub_item_id
    );
    return itemIndex + 1;
  }

  function handleTrack({
    section,
    isParent = false,
    parent = null,
    item,
    datasetItemId,
  }) {
    const absolutePos = getAbsolutePosition(section, datasetItemId);

    const relativePos = getRelativePosition(parent, item, isParent, section);

    const trackPayload = {
      'Posição relativa': relativePos,
      'Posição absoluta': absolutePos,
      Sessão: section === 'drug' ? 'Drogas' : 'Rotinas',
      'Termo buscado': inputValue,
      'Título do pai': isParent
        ? `${item.title} - ${item.legend}`
        : `${parent.title} - ${parent.legend}`,
      'Título do filho': isParent ? '' : `${item.title} - ${item.legend}`,
      'É pai': isParent,
    };

    trackEvent('Busca - navegou por um resultado', trackPayload);
  }

  function handleRedirectByClick(type, clickedItem, parent = null) {
    const selector = {
      routine: () => {
        const redirectType = {
          routine: () => {
            const routineId = clickedItem.item_id;

            history.push(`/app/routines/${routineId}`, {
              trackOrigin: 'Lista de resultados da busca',
              trackType: 'Busca',
            });

            handleTrack({
              section: 'routine',
              item: clickedItem,
              datasetItemId: `${routineId}`,
              isParent: true,
            });
          },
          card: () => {
            const routineId = parent.item_id;
            const tabId = clickedItem.legend_id;
            const cardId = clickedItem.sub_item_id;

            history.push(`/app/routines/${routineId}/${tabId}/${cardId}`, {
              trackOrigin: 'Lista de resultados da busca',
              trackType: 'Busca',
            });

            handleTrack({
              section: 'routine',
              item: clickedItem,
              datasetItemId: `${parent.legend_id}${routineId}${cardId}`,
              parent: parent,
            });
          },
          content: () => {
            const routineId = parent.item_id;
            const tabIdAndCardId = clickedItem.legend_id;
            const contentId = clickedItem.sub_item_id;

            history.push(`/app/routines/${routineId}/${tabIdAndCardId}/${contentId}`, {
              trackOrigin: 'Lista de resultados da busca',
              trackType: 'Busca',
            });

            handleTrack({
              section: 'routine',
              item: clickedItem,
              datasetItemId: `${parent.legend_id}${routineId}${contentId}`,
              parent: parent,
            });
          }
        };
        return (
          redirectType[clickedItem?.sub_type]?.() ||
          redirectType[clickedItem?.type]?.() ||
          null
        );
      },
      drug: () => {
        const redirectType = {
          therapeutic_group: () => {
            const therapeuticGroupId = clickedItem.item_id;

            history.push(`/app/drugs/${therapeuticGroupId}`, {
              trackOrigin: 'Lista de resultados da busca',
              trackType: 'Busca',
            });

            handleTrack({
              section: 'drug',
              item: clickedItem,
              datasetItemId: `${therapeuticGroupId}`,
              isParent: true,
            });
          },
          active_principle: () => {
            const therapeuticGroupId = clickedItem.legend_id;
            const activePrincipleId = clickedItem.item_id;

            history.push(
              `/app/drugs/${therapeuticGroupId}/${activePrincipleId}`,
              {
                trackOrigin: 'Lista de resultados da busca',
                trackType: 'Busca',
              }
            );

            handleTrack({
              section: 'drug',
              item: clickedItem,
              datasetItemId: `${therapeuticGroupId}${activePrincipleId}`,
              isParent: true,
            });
          },
          resume: () => {
            const therapeuticGroupId = parent.legend_id;
            const activePrincipleId = parent.item_id;

            history.push(
              `/app/drugs/${therapeuticGroupId}/${activePrincipleId}`,
              {
                trackOrigin: 'Lista de resultados da busca',
                trackType: 'Busca',
              }
            );

            handleTrack({
              section: 'drug',
              item: clickedItem,
              datasetItemId: `${therapeuticGroupId}${activePrincipleId}${clickedItem.sub_item_id}`,
              parent: parent,
            });
          },
          brand: () => {
            const therapeuticGroupId = parent.legend_id;
            const activePrincipleId = parent.item_id;
            const brandId = clickedItem.sub_item_id;

            history.push(
              `/app/drugs/${therapeuticGroupId}/${activePrincipleId}/${brandId}`,
              {
                trackOrigin: 'Lista de resultados da busca',
                trackType: 'Busca',
              }
            );

            handleTrack({
              section: 'drug',
              item: clickedItem,
              datasetItemId: `${therapeuticGroupId}${activePrincipleId}${brandId}`,
              parent: parent,
            });
          },
          dosage: () => {
            const therapeuticGroupId = parent.legend_id;
            const activePrincipleId = parent.item_id;
            const dosageId = clickedItem.sub_item_id;

            history.push(
              `/app/drugs/${therapeuticGroupId}/${activePrincipleId}/${dosageId}`,
              {
                trackOrigin: 'Lista de resultados da busca',
                trackType: 'Busca',
              }
            );

            handleTrack({
              section: 'drug',
              item: clickedItem,
              datasetItemId: `${therapeuticGroupId}${activePrincipleId}${dosageId}`,
              parent: parent,
            });
          },
        };
        return (
          redirectType[clickedItem?.sub_type]?.() ||
          redirectType[clickedItem?.type]?.() ||
          null
        );
      },
      calculator: () => {
        const calcName = clickedItem.item_id;
        history.push(`/app/calculators/${calcName}`, {
          trackOrigin: 'Lista de resultados da busca',
          trackType: 'Busca',
        });

        const trackPayload = {
          Sessão: 'Calculadoras',
          'Termo buscado': inputValue,
          'Título do pai': clickedItem.title,
        };

        trackEvent('Busca - navegou por um resultado', trackPayload);
      },
      cid: () => {
        const subCat = clickedItem.aux.split('.')[0];
        const cat = cidCats.filter((e) => e.CAT >= subCat && e.CAT <= subCat)[0]
          .CAT;
        const unparsedGroup = cidGroups.filter(
          (e) => e.CATFIM >= cat && e.CATINIC <= cat
        );
        const group = unparsedGroup[0].CATINIC + '-' + unparsedGroup[0].CATFIM;
        const chapter = cidCaps.filter(
          (e) =>
            e.CATINIC <= unparsedGroup[0].CATINIC &&
            e.CATFIM >= unparsedGroup[0].CATFIM
        )[0].NUMCAP;
        history.push(`/app/CID/${chapter}/${group}/${cat}`);
        const trackPayload = {
          Sessão: 'CID',
          'Termo buscado': inputValue,
          'Título do pai': clickedItem.title,
        };

        trackEvent('Busca - navegou por um resultado', trackPayload);
      },
    };

    selector[type]();
    onClose();
  }

  function getItemIndex(type, item) {
    return response.searchResult[`${type}s`].data.findIndex(
      (e) => e.item_id === item.item_id && e.legend_id === item.legend_id
    );
  }

  function handleClickItem({
    type,
    clickedItem,
    parent = null,
    hasSubItems = false,
  }) {
    if (!hasSubItems) {
      return handleRedirectByClick(type, clickedItem, parent);
    }

    const itemIndex = getItemIndex(type, clickedItem);

    const trackPayload = {
      'Posição relativa': itemIndex + 1,
      Sessão: type === 'drug' ? 'Drogas' : 'Rotinas',
      'Termo buscado': inputValue,
      'Título do item': clickedItem.title,
    };

    trackEvent('Busca - expandiu resultados', trackPayload);
  }

  function onShowMoreClick(type, results, isClickedToOpen) {
    if (!isClickedToOpen) return null;

    const trackPayload = {
      Sessão: type === 'drugs' ? 'Drogas' : 'Rotinas',
      'Termo buscado': inputValue,
      'Top 1': results.data[0].title,
      'Top 2': results.data[1].title,
      'Top 3': results.data[2].title,
    };

    trackEvent('Busca - ver mais', trackPayload);
  }

  const [filteredSections, setFilteredSections] = useState('all');

  function handleRenderSections(data) {
    const sections = {
      drugs: {
        title: 'Drogas',
        icon: <BbDrugIcon primaryColor="#999999" />,
        results: data.drugs,
        onShowMoreClick: (isClickedToOpen) =>
          onShowMoreClick('drugs', data.drugs, isClickedToOpen),
      },
      routines: {
        title: 'Rotinas',
        icon: <BbRoutineIcon primaryColor="#999999" />,
        results: data.routines,
        onShowMoreClick: (isClickedToOpen) =>
          onShowMoreClick('routines', data.routines, isClickedToOpen),
      },
      calculators: {
        title: 'Calculadoras',
        icon: <Calculator secondaryColor="#999999" />,
        results: data.calc,
        onShowMoreClick: (isClickedToOpen) =>
          onShowMoreClick('calculators', data.calc, isClickedToOpen),
      },
      cid: {
        title: 'CID',
        icon: <CidIconSimple />,
        results: data.cid,
        onShowMoreClick: (isClickedToOpen) =>
          onShowMoreClick('cid', data.cid, isClickedToOpen),
      },
    };

    function handleFiters(section_name) {
      if (filteredSections !== 'all' && filteredSections !== section_name) {
        return { display: 'none' };
      }
      return null;
    }

    return (
      <>
        {sections?.drugs?.results?.length > 0 && (
          <SearchResultCardComponent
            hasFavorites={true}
            type="drugs"
            style={handleFiters('content')}
            onItemClick={(item, hasSubItems) =>
              handleClickItem({
                type: 'drug',
                clickedItem: item,
                hasSubItems: hasSubItems,
              })
            }
            onSubItemClick={(item, parent) =>
              handleClickItem({
                type: 'drug',
                clickedItem: item,
                parent: parent,
              })
            }
            {...sections.drugs}
          />
        )}
        {sections?.routines?.results?.length > 0 && (
          <SearchResultCardComponent
            hasFavorites={true}
            type="routines"
            style={handleFiters('content')}
            onItemClick={(item, hasSubItems) =>
              handleClickItem({
                type: 'routine',
                clickedItem: item,
                hasSubItems: hasSubItems,
              })
            }
            onSubItemClick={(item, parent) =>
              handleClickItem({
                type: 'routine',
                clickedItem: item,
                parent: parent,
              })
            }
            {...sections.routines}
          />
        )}

        {filteredSections === 'content' &&
          !sections?.routines?.results?.length > 0 &&
          !sections?.drugs?.results?.length > 0 && (
            <div className="emptySearch__container">
              <EmptySearchImage />
              <h3>Não encontramos nenhum resultado para esta categoria</h3>
            </div>
          )}
        {sections?.calculators?.results?.length > 0 ? (
          <SearchResultCardComponent
            hasFavorites={false}
            style={handleFiters('calc')}
            onItemClick={(item) =>
              handleClickItem({
                type: 'calculator',
                clickedItem: item,
                hasSubItems: false,
              })
            }
            onSubItemClick={(item, parent) =>
              handleClickItem({
                type: 'calculator',
                clickedItem: item,
                parent: parent,
              })
            }
            {...sections.calculators}
          />
        ) : (
          filteredSections === 'calc' && (
            <div className="emptySearch__container">
              <EmptySearchImage />
              <h3>Não encontramos nenhum resultado para esta categoria</h3>
            </div>
          )
        )}
        {sections?.cid?.results?.length > 0 ? (
          <SearchResultCardComponent
            hasFavorites={false}
            style={handleFiters('cid')}
            onItemClick={(item) =>
              handleClickItem({
                type: 'cid',
                clickedItem: item,
                hasSubItems: false,
              })
            }
            onSubItemClick={(item, parent) =>
              handleClickItem({
                type: 'cid',
                clickedItem: item,
                parent: parent,
              })
            }
            {...sections.cid}
          />
        ) : (
          filteredSections === 'cid' && (
            <div className="emptySearch__container">
              <EmptySearchImage />
              <h3>Não encontramos nenhum resultado para esta categoria</h3>
            </div>
          )
        )}
      </>
    );
  }

  function validateEmptyAllSections(data) {
    return (
      data?.drugs?.length > 0 ||
      data?.routines?.length > 0 ||
      data?.calculators?.length > 0 ||
      data?.cid?.length > 0
    );
  }

  return (
    <SearchContainerStyle>
      <FilterContainer>
        <button
          className={filteredSections === 'all' && 'active'}
          onClick={() => setFilteredSections('all')}
        >
          Todos
        </button>
        <button
          className={filteredSections === 'content' && 'active'}
          onClick={() => setFilteredSections('content')}
        >
          Conteúdo
        </button>
        <button
          className={filteredSections === 'cid' && 'active'}
          onClick={() => setFilteredSections('cid')}
        >
          CID-10
        </button>
        <button
          className={filteredSections === 'calc' && 'active'}
          onClick={() => setFilteredSections('calc')}
        >
          Calculadoras
        </button>
      </FilterContainer>

      {response.loading ? (
        <div className="default__container">
          <Spinner />
        </div>
      ) : response.error ? (
        <div className="default__container">
          Ocorreu um erro. Tente novamente mais tarde.
        </div>
      ) : validateEmptyAllSections(response?.searchResult) ? (
        handleRenderSections(response?.searchResult)
      ) : (
        <div className="emptySearch__container">
          <EmptySearchImage />
          <h3>
            {!!response?.searchResult
              ? 'Não encontramos nenhum resultado'
              : 'Busque por ROTINAS, DROGAS, CALCULADORAS e CID!'}
          </h3>
        </div>
      )}
    </SearchContainerStyle>
  );
}
